import type { Project } from '@pidk/compose/src/types/compose'
import { authenticateProject } from '@pidk/web/src/api/projects'
import { clearProjectCookie } from '@pidk/web/src/utils/tokenStore'
import * as Sentry from '@sentry/nextjs'
import { useEffect, useState } from 'react'

interface ProjectAuthenticateState {
  isAuthenticated: boolean
  isLoaded: boolean
}

const getInitialState = (project: Project, isPreview): ProjectAuthenticateState => {
  const isPasswordProtected = project?.passwords?.length > 0

  if (isPasswordProtected && !isPreview) {
    return {
      isAuthenticated: false,
      isLoaded: false
    }
  }

  if (isPasswordProtected && isPreview) {
    return {
      isAuthenticated: true,
      isLoaded: true
    }
  }

  return {
    isAuthenticated: true,
    isLoaded: true
  }
}

const useProjectAuthenticate = (project: Project, isPreview: boolean): ProjectAuthenticateState => {
  const [projectAuthState, setProjectAuthState] = useState<ProjectAuthenticateState>(getInitialState(project, isPreview))

  useEffect(() => {
    if (projectAuthState.isAuthenticated) {
      return
    }

    (async () => {
      try {
        await authenticateProject(project.id)

        setProjectAuthState(prevState => ({
          ...prevState,
          isAuthenticated: true,
          isLoaded: true
        }))
      } catch (e) {
        setProjectAuthState(prevState => ({
          ...prevState,
          isAuthenticated: false,
          isLoaded: true
        }))

        clearProjectCookie()

        Sentry.captureException(e)
      }
    })()
  }, [project.id])

  return projectAuthState
}

export default useProjectAuthenticate

import { apiRequest } from '@/utils/apiRequest'

interface ICreateRoomReqBody {
  hostSocketId: string
  projectId: string
  code?: string
}

export const createRoom = async (hostSocketId: string, projectId: string, code?: string) => {
  const reqBody: ICreateRoomReqBody = {
    hostSocketId,
    projectId
  }

  if (code) {
    reqBody.code = code
  }

  return await apiRequest('rooms', {
    method: 'POST',
    body: {
      ...reqBody
    }
  })
}

export const createRoomInteraction = async (hostSocketId: string, code: string, interactionType: string, interactionData: any) => {
  return await apiRequest('rooms/create-interaction', {
    method: 'POST',
    body: {
      hostSocketId,
      code,
      interactionType,
      interactionData
    }
  })
}

export const getRoomInteractionResults = async (hostSocketId: string, code: string, interactionId: string) => {
  return await apiRequest('rooms/get-interaction-results', {
    method: 'POST',
    body: {
      hostSocketId,
      code,
      interactionId
    }
  })
}

export const deactivateRoom = async (hostSocketId: string, code: string) => {
  return await apiRequest('rooms/deactivate-room', {
    method: 'POST',
    body: {
      hostSocketId,
      code
    }
  })
}

export const activateRoom = async (hostSocketId: string, code: string) => {
  return await apiRequest('rooms/activate-room', {
    method: 'POST',
    body: {
      hostSocketId,
      code
    }
  })
}

export const roomClearLastInteraction = async (hostSocketId: string, code: string) => {
  return await apiRequest('rooms/clear-last-interaction', {
    method: 'POST',
    body: {
      hostSocketId,
      code
    }
  })
}

interface RoomJoinBody {
  code: string
  socketId: string
  participantId?: string
}

export const roomJoin = async (code: string, socketId: string, participantId?: string) => {
  let reqBody: RoomJoinBody = {
    code,
    socketId
  }

  if (participantId !== undefined) {
    reqBody.participantId = participantId
  }

  return await apiRequest('rooms/join', {
    method: 'POST',
    body: {
      ...reqBody
    }
  })
}

export const roomLeave = async (code: string, participantId: string) => {
  return await apiRequest('rooms/leave', {
    method: 'POST',
    body: {
      code: code.toString(),
      participantId: participantId
    }
  })
}

interface NewParticipantInteraction {
  interactionId: string
  interactionType: string
  interactionData: any
}

export const submitRoomParticipantInteraction = async (code: string, participantId: string, newInteraction: NewParticipantInteraction) => {
  return await apiRequest('rooms/create-participant-interaction', {
    method: 'POST',
    body: {
      code: code.toString(),
      participantId,
      newInteraction
    }
  })
}

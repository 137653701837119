import type { IEventCreateBody } from '@pidk/api/src/types/api'

import { apiRequest } from '@/utils/apiRequest'

export const createEvent = async (body: IEventCreateBody) => {
  return apiRequest('events', {
    method: 'POST',
    body
  })
}
